import React from 'react';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';

export function Home() {
  return (
    <Container fixed>
      <Box sx={{ textAlign: 'center', height:'200px' }}>

      </Box>
    </Container >
  )
}

export default Home;